<template>
  <div class="steps-container">
    <div>
      <div class="steps-main-container">
        <div class="steps-item">
          <div class="steps-title">入驻步骤说明</div>
          <div class="steps-item-title">01 准备资料</div>
          <div class="steps-item-text">1. 点击查询入驻所需资质（不同的主营类目所需的入驻资质不同）</div>
          <div class="steps-item-text">2. 建议您事先准备齐全资料，一次性通过审核</div>
          <span class="prepare-settle" @click="openPrepareSettle">查看入驻准备</span>
        </div>
        <div class="circle">
           <i class="el-icon-arrow-right"></i>
        </div>
        <div class="steps-item">
          <div class="steps-item-title">02 入驻准备</div>
          <div class="steps-item-text">1. 选择主营类目</div>
          <div class="steps-item-text">2. 根据页面提示正确填写您的入驻信息、上传相关资质</div>
        </div>
        <div class="circle">
           <i class="el-icon-arrow-right"></i>
        </div>
        <div class="steps-item">
          <div class="steps-item-title">03 资质审核</div>
          <div class="steps-item-text">1. 审核时效：普通类目店铺审核时效1个工作日以内；特殊类目店铺审核时效3个工作日（以实际审核为准）</div>
          <div class="steps-item-text">2. 短信通知：无论是否审核通过，我们将通过短信通知您审核结果</div>
        </div>
        <div class="circle">
           <i class="el-icon-arrow-right"></i>
        </div>
        <div class="steps-item">
          <div class="steps-item-title">04 开店成功</div>
          <div class="steps-item-text">1. 前往商家后台开始致富之旅</div>
          <div class="steps-item-text">2. 商家后台：您可以登录农博商家管理后台，也可以下载农博商家版APP管理店铺</div>
        </div>
      </div>
    </div>
    <div class="operate-container">
      <el-button style="width: 300px;" type="primary" size="medium" @click="toInfo">我要开店</el-button>
    </div>
    <el-dialog
      :visible="dialogVisible"
      width="70%"
      top="5vh"
      @close="dialogVisible = false"
    >
      <div class="prepare-settle-container">
        <prepareSettle />
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import prepareSettle from './prepareSettle.vue';
  export default {
    components: {
      prepareSettle
    },
    data() {
      return {
        origin: '',
        dialogVisible: false
      }
    },
    created() {
      this.origin = this.$route.query.origin
    },
    methods: {
      openPrepareSettle() {
        this.dialogVisible = true
      },
      toInfo() {
        this.$router.push({
          path: '/settle/cfnb/register',
          query: { origin: this.origin }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .steps-container {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .prepare-settle-container {
      max-height: 70vh;
      overflow: auto;
    }
    .steps-main-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .steps-item {
        position: relative;
        .steps-title {
          position: absolute;
          top: -60px;
          left: 0;
          font-size: 18px;
          margin: 20px 0;
          font-weight: bold;
        }
        border: 1px solid #ccc;
        width: 20%;
        padding: 20px;
        height: 160px;
        border-radius: 10px;
        .steps-item-title {
          margin-bottom: 15px;
        }
        .steps-item-text {
          margin-bottom: 10px;
        }
        .prepare-settle {
          color: #409EFF;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .circle {
        border: 1px solid #666;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px;
        flex-shrink: 0;
        border-radius: 20px;
      }
    }
    .operate-container {
      display: flex;
      justify-content: center;
      margin-top: 60px;
    }
  }
</style>
